<template>
	<div class="contacts-banner">
		<div class="app-container">
			<h1 class="heading-2 green--text contacts__maintitle">{{ $t('main_content.contacts.banner.title') }}</h1>
			<p class='contacts__maindesc'>{{ $t('main_content.contacts.banner.description') }}</p>

			<div class="contacts-banner-block__list">
				<div class="contacts-banner-block__item">
					<h2 class="contacts-banner-block__title heading-3">{{ $t('main_content.contacts.banner.item_1.title') }}</h2>
					<p class="contacts-banner-block__desc">{{ $t('main_content.contacts.banner.item_1.description') }}</p>
					<v-button color='black' class='white' :to="{hash: '#contacts-info'}" @click.native="scrollFix('#contacts-info')">{{ $t('main.buttons.contacts_us') }}</v-button>
				</div>

				<div class="contacts-banner-block__item">
					<h2 class="contacts-banner-block__title heading-3">{{ $t('main_content.contacts.banner.item_2.title') }}</h2>
					<p class="contacts-banner-block__desc">{{ $t('main_content.contacts.banner.item_2.description') }}</p>
					<v-button color='white' outline :to='$i18nRoute({name: "faq"})'>{{ $t('main_content.contacts.banner.item_2.btn_word') }}</v-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['scrollFix']
	}
</script>


<style lang='scss'>
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.contacts {
		&-banner {
			position: relative;
			background-color: rgba($green, .15);
			padding: 50px 0 80px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 100px * .6 0 87px * .6;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 40px * .8 0 87px * .8;
			}
			@include minw( $grid-breakpoints-lg ) {
				padding: 40px 0 87px;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(#C4C4C4, .15);
				mask-image: url('../../../assets/images/background-lines.svg');
			}
			.heading-2 {
				margin-bottom: 16px;
			}
			&-block {
				&__list {
					@include minw( $grid-breakpoints-xs ) {
						display: flex;
						flex-direction: row;
						justify-content: center;
					}
					@include minw( $grid-breakpoints-sm ) {
						align-items: flex-start;
					}
				}
				&__item {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding: 30px 25px;
					min-height: 287px;
					margin-bottom: 20px;
					@include minw( $grid-breakpoints-xs ) {
						flex: 0 0 50%;
						padding: 45px * .8 40px * .8;
						width: 680px * .8;
						max-width: 680px * .8;
						min-height: 420px * .7;
						margin-bottom: 0;
					}
					@include minw( $grid-breakpoints-lg ) {
						padding: 45px 40px;
						width: 680px;
						max-width: 680px;
						min-height: 420px;
					}
					&:nth-child(1) {
						background-color: $green-dark;
						@include minw( $grid-breakpoints-sm ) {
							margin-top: 80px * .8;
							transform: translateX(110px * .8);
							padding-right: 125px * .8;
						}
						@include minw( $grid-breakpoints-lg ) {
							margin-top: 80px;
							transform: translateX(110px);
							padding-right: 125px;
						}
					}
					&:nth-child(2) {
						z-index: 2;
						background-color: $green;
						@include minw( $grid-breakpoints-sm ) {
							margin-top: -50px * .8;
						}
						@include minw( $grid-breakpoints-lg ) {
							margin-top: -50px;
						}
					}
					.app-btn {
						margin-top: auto;
						@include maxw( $grid-breakpoints-sm - 1 ) {
							min-width: auto;
							width: 100%;
						}
					}
				}
				&__title,
				&__desc {
					max-width: 510px * .8;
					@include minw( $grid-breakpoints-lg ) {
						max-width: 510px;
					}
				}
				&__title {
					color: $white;
					font-family: 'HN-Bold', sans-serif;
					margin-bottom: 17px;
					@include minw( $grid-breakpoints-sm ) {
						margin-bottom: 27px;
					}
				}
				&__desc {
					color: $white;
					font-size: 18px * .8;
					margin-bottom: 40px;
					@include minw( $grid-breakpoints-lg ) {
						font-size: 18px;
					}
				}
			}
		}
	}
</style>
