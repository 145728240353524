<template>
	<div class="contacts-info" id='contacts-info' ref='contacts-info'>
		<div class="app-container">
			<div class="row align-items-center">
				<div class="col-6">
					<AppDoubleImage
						class='bottom left'
						firstImgSrc='contacts-page/contacts-info-img.jpg'
					/>
				</div>
				<div class="col-6">
					<div class="contacts-info__cont">
						<h2 class="heading-2 green--text contacts-info__title">{{ $t("main_content.contacts.info.title") }}</h2>
						<ul>
							<li>
								<span>{{ $t("main_content.contacts.info.place.title") }}:</span>
								<a href="https://goo.gl/maps/Rp4xYPKAUcXFXi4TA" target="_blank" class='contacts-info__value link'>{{ $t("main_content.contacts.info.place.address.city") }} <br> {{ $t("main_content.contacts.info.place.address.street") }}</a>
							</li>
							<li>
								<span>{{ $t("main_content.contacts.info.telephone.title") }}:</span>
								<a
									:href="'tel:'+phone"
									class='contacts-info__value link'
									:key='"phone_key_"+i'
									v-for='(phone, i) in $t("main_content.contacts.info.telephone.list")'
									>{{ phone }}</a>
							</li>
							<li>
								<span>{{ $t("main_content.contacts.info.shchedule.title") }}:</span>
								<p class='contacts-info__value'>{{ $t("main_content.contacts.info.shchedule.monday") }} - {{ $t("main_content.contacts.info.shchedule.friday") }} 09.00-19.00</p>
								<p class='contacts-info__value'>{{ $t("main_content.contacts.info.shchedule.saturday") }} 10.00-16.00</p>
							</li>
							<li>
								<span>{{ $t("main_content.contacts.info.email") }}:</span>
								<a
									href="mailto:info@tcc.com.ua"
									class='contacts-info__value link'
									>info@tcc.com.ua</a>
							</li>
						</ul>

						<a href="https://goo.gl/maps/Rp4xYPKAUcXFXi4TA" target="_blank" class="app-btn green white--text" type="button">
							<div class="app-btn__content">{{$t('main_content.contacts.info.map')}}</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import AppDoubleImage from '@/components/main/AppDoubleImage.vue';

	export default {
		components: {
			AppDoubleImage
		}
	}
</script>

<style lang='scss'>
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.contacts {
		&__maindesc {
			font-size: 14px;
			padding-bottom: 40px;
			@include minw( $grid-breakpoints-sm ) {
				font-size: 22px * .9;
				width: 50%;
				padding-right: 20px;
				padding-bottom: 40px * .6;
			}
			@include minw( $grid-breakpoints-lg ) {
				font-size: 22px;
				padding-bottom: 40px;
			}
		}
		&-info {
			@include minw( $grid-breakpoints-sm ) {
				padding: 150px 0 100px;
			}
			@include maxw( $grid-breakpoints-sm - 1 ) {
				.col-6:first-child {
					padding-left: 0;
					padding-right: 0;
				}
			}
			&__cont {
				padding: 50px 0 60px;
				@include minw( $grid-breakpoints-sm ) {
					padding: 0;
				}
				.app-btn {
					width: 100%;
					@include minw( $grid-breakpoints-xs ) {
						width: auto;
					}
				}
			}
			&__title {
				margin-bottom: 30px;
				@include minw( $grid-breakpoints-sm ) {
					margin-bottom: 50px;
				}
			}
			&__value {
				font-size: 18px;
				max-width: 250px;
				&.link {
					&:hover {
						color: $green-active;
					}
				}
			}
			ul {
				@include minw( $grid-breakpoints-xs ) {
					display: flex;
					flex-wrap: wrap;
					width: 100%;
				}
				li {
					margin-bottom: 25px;
					padding-left: 30px;
					padding-right: 30px;
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					@include minw( $grid-breakpoints-xs ) {
						width: 48%;
						margin-bottom: 40px;
					}
					&::before {
						content: '';
						position: absolute;
						display: block;
						width: 10px;
						height: 10px;
						background-color: $green;
						left: 0;
						top: 5px;
						border-radius: 50%;
					}
					span {
						color: rgba($black, .4);
						padding-bottom: 5px;
					}
				}
			}
		}
	}
</style>
