<template>
	<div class="contacts">
		<contacts-banner :scrollFix='scrollFix' />
		<contacts-info />
	</div>
</template>

<script>
	import ContactsBanner from '@/views/main/components/ContactsBanner.vue';
	import ContactsInfo from '@/views/main/components/ContactsInfo.vue';

	export default {
		components: {
			ContactsBanner,
			ContactsInfo
		},
		methods: {
			scrollFix(hashbang) {
				const container = document.querySelector(hashbang).offsetTop;
				const header = document.querySelector('.header').offsetHeight;

				window.scrollTo({
					top: container - header,
					behavior: 'smooth'
				})
			}
		}
	}
</script>
