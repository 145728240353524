<template>
	<div :class="classes">
		<div class="double-img__item double-img__item--first">
			<img class='double-img__item-img' :src="require(`@/assets/images/${firstImgSrc}`)" />
		</div>

		<div class="double-img__item double-img__item--second" v-if="secondImgSrc">
			<img class='double-img__item-img' :src="require(`@/assets/images/${secondImgSrc}`)" />
			<img class='double-img__item-img hidden' :src="require(`@/assets/images/${secondImgSrc}`)" />
		</div>

		<div class="double-img__item double-img__item--second" v-else>
			<div class="double-img__item-img overlay"></div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			firstImgSrc: {
				type: String,
				required: true
			},
			secondImgSrc: String
		},
		computed: {
			classes() {
				return {
					"double-img": true,
					hovery: this.secondImgSrc ? true : false
				};
			}
		}
	};
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.double-img {
		position: relative;
		@include minw($grid-breakpoints-sm) {
			flex-shrink: 0;
			max-width: 540px;
		}
		@include minw($grid-breakpoints-sm) {
			&.top {
				&.right {
					margin-top: 70px;
					margin-right: 50px;
					.double-img__item--second {
						top: -70px;
						right: -50px;
					}
				}
			}
			&.bottom {
				&.right {
					margin-bottom: 55px;
					margin-right: 70px;
					@include minw($grid-breakpoints-md) {
						margin-right: 0;
					}
					.double-img__item--second {
						bottom: -55px;
						right: -70px;
					}
				}
				&.left {
					margin-bottom: 70px;
					margin-left: 50px;
					.double-img__item--second {
						bottom: -70px;
						left: -50px;
					}
				}
			}
		}
		&.hovery {
			@include minw($grid-breakpoints-md) {
				&:hover {
					.double-img__item-img.hidden {
						opacity: 1;
					}
				}
			}
		}
		&__item {
			height: 74.4vw;
			width: 100%;
			position: relative;
			@include minw($grid-breakpoints-xs) {
				height: 400px;
			}
			@include minw($grid-breakpoints-sm) {
				height: 400px * .8;
			}
			@include minw($grid-breakpoints-md) {
				height: 400px;
			}
			&--first {
				z-index: 2;
			}
			&--second {
				display: none;
				@include minw($grid-breakpoints-sm) {
					display: block;
					position: absolute;
				}
			}
			&-img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				display: block;
				&.hidden {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					opacity: 0;
					z-index: 5;
					transition: $transition;
				}
				&.overlay {
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: $green-dark;
				}
			}
		}
	}
</style>
